import { combineReducers } from 'redux';
import userInformation from './userReducer';
import loaderReducer from './loaderReducer';
import notificationReducer from './notificationsReducer';

export const rootReducer = combineReducers({
  userInformation,
  loader: loaderReducer,
  notifications: notificationReducer,
});
