const initialState = {
  show: false,
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_LOADER':
      return {
        ...state,
        show: true,
      };
    case 'HIDDEN_LOADER':
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};

export default loaderReducer;
