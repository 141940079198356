/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';

const MsalInstance = ({ children }) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const cleanup = useRef(false);
  const [render, setRender] = useState(false);
  useEffect(() => {
    if (!cleanup.current) {
      dispatch({ type: 'SET_INSTANCE', payload: instance });
      setRender(true);
    }
    return function () {
      cleanup.current = true;
    };
  }, []);
  return <>{render ? <>{children}</> : <></>}</>;
};

export default MsalInstance;
