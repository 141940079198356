import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Router from './router';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './configs/azure';
import MsalInstance from './components/Wrapper/MsalInstance';
const msalInstance = new PublicClientApplication(msalConfig);

const App = (props) => {
  return (
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <MsalInstance>
          <Router />
        </MsalInstance>
      </MsalProvider>
    </Provider>
  );
};

export default App;
