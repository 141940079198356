const initialState = {
  user: {},
  auth: false,
  ssoInstance: {},
};

const userInformation = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.payload,
        auth: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: action.payload,
        auth: false,
      };
    case 'PROFILE':
      return {
        ...state,
        user: action.payload,
        auth: true,
      };
    case 'SET_INSTANCE':
      return {
        ...state,
        ssoInstance: action.payload,
      };
    case 'REMOVE_INSTANCE':
      return {
        ...state,
        ssoInstance: {},
      };
    default:
      return state;
  }
};

export default userInformation;
