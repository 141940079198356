const jsonParse = (envValue, defaultValue, parse = true) => {
  try {
    if (envValue === null || envValue === '' || typeof envValue === 'undefined') {
      return defaultValue;
    } else {
      if (parse) {
        return JSON.parse(envValue);
      } else if (parse === false) {
        return envValue;
      }
    }
  } catch (error) {
    return defaultValue;
  }
};

const env = () => {
  return {
    NODE_ENV: jsonParse(process.env.NODE_ENV, 'development', false),
    STRICT_MODE: jsonParse(process.env.REACT_APP_STRICT_MODE, 'on', false),
    SSO_AZURE_CLIENT_ID: jsonParse(process.env.REACT_APP_SSO_AZURE_CLIENT_ID, '', false),
    SSO_AZURE_AUTHORITY: jsonParse(process.env.REACT_APP_SSO_AZURE_AUTHORITY, '', false),
    SSO_AZURE_REDIRECT_URL: jsonParse(process.env.REACT_APP_SSO_AZURE_REDIRECT_URL, 'http://localhost:3000/', false),
    SSO_AZURE_CACHE_LOCATION: jsonParse(process.env.REACT_APP_SSO_AZURE_CACHE_LOCATION, 'sessionStorage', false),
    SSO_AZURE_STORE_AUTH_STATE_IN_COOKIE: jsonParse(
      process.env.REACT_APP_SSO_AZURE_STORE_AUTH_STATE_IN_COOKIE,
      false,
      true
    ),
    TINYMCE_API_KEY: jsonParse(process.env.REACT_APP_TINYMCE_API_KEY, '', false),
    BACKEND_API_URL: jsonParse(process.env.REACT_APP_API_URL, '', false),
    LOGOUT_URL: jsonParse(process.env.REACT_APP_LOGOUT_URL, '', false),
    MAX_FILE_COMPANY_REGULATIONS: jsonParse(process.env.REACT_APP_MAX_FILE_COMPANY_REGULATIONS, 5, true),
    MAX_FILE_AUDIT_SUBMISSION_SPA: jsonParse(process.env.REACT_APP_MAX_FILE_AUDIT_SUBMISSION_SPA, 2, true),
    MAX_FILE_LHA: jsonParse(process.env.REACT_APP_MAX_FILE_LHA, 2, true),
    MAX_FILE_LHA_COVER_LETTER: jsonParse(process.env.REACT_APP_MAX_FILE_LHA_COVER_LETTER, 2, true),
    MAX_FILE_AUDIT_SUBMISSION_OTHER_INFORMATION_AND_SAMSAT_DATA: jsonParse(
      process.env.REACT_APP_MAX_FILE_AUDIT_SUBMISSION_OTHER_INFORMATION_AND_SAMSAT_DATA,
      2,
      true
    ),
    LIMIT_QAASSESSMENTTYPE: jsonParse(process.env.REACT_APP_LIMIT_QAASSESSMENTTYPE, 5, true),
  };
};
// jsonParse(process.env.REACT_APP_)
export default env;
