const initialState = {
  reload: false,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RELOAD_STATE':
      return {
        ...state,
        reload: !state.reload,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
