import React from 'react';
import { Suspense, lazy } from 'react';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Loader from '../components/base/Loader';
import 'react-toastify/dist/ReactToastify.css';
// import { useRouter } from '../hooks/local';
const Login = lazy(() => import('../pages/Login'));
const DashboardLayout = lazy(() => import('../components/Layout/DashboardLayout'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
// const ObjectAudit = lazy(() => import('../pages/ObjectAudit'));
// const Branch = lazy(() => import('../pages/Branch'));
const UserManagement = lazy(() => import('../pages/UserManagement'));
const RoleAccess = lazy(() => import('../pages/RoleAccess'));
const EmployeeDailyPocketMoney = lazy(() => import('../pages/EmployeeDailyPocketMoney'));
// const PartnersDailyPocketMoney = lazy(() => import('../pages/PartnersDailyPocketMoney'));
const AuditAllowance = lazy(() => import('../pages/AuditAllowance'));
// const TaxiCost = lazy(() => import('../pages/TaxiCost'));
// const InitialAuditEvaluation = lazy(() => import('../pages/InitialAuditEvaluation'));
const SpinMaturityLevel = lazy(() => import('../pages/SpinMaturityLevel'));
const SpinAttribute = lazy(() => import('../pages/SpinAttribute'));
const SpinFocalPoint = lazy(() => import('../pages/SpinFocalPoint'));
const SpinControlElement = lazy(() => import('../pages/SpinControlElement'));
const QASubElementsofAssessment = lazy(() => import('../pages/QASubElementsofAssessment'));
const QAAssessmentType = lazy(() => import('../pages/QAAssessmentType'));
const QAAssessmentElements = lazy(() => import('../pages/QAAssessmentElements'));
const ObjPrioritasBobot = lazy(() => import('../pages/ObjPrioritasBobot'));
const ObjPrioritasFraud = lazy(() => import('../pages/ObjPrioritasFraud'));
const AnnualAuditPlanning = lazy(() => import('../pages/AnnualAuditPlanning'));
const FindingCode = lazy(() => import('../pages/FindingCode'));
const CompanyRegulations = lazy(() => import('../pages/CompanyRegulations'));
const AuditSubmission = lazy(() => import('../pages/AuditSubmission'));
const FormAuditSubmission = lazy(() => import('../pages/FormAuditSubmission'));
const PTTAPlanning = lazy(() => import('../pages/PTTAPlanning'));
const FieldPlanning = lazy(() => import('../pages/FieldPlanning'));
const AffairsPlanning = lazy(() => import('../pages/AffairsPlanning'));
const OfficePlanning = lazy(() => import('../pages/OfficePlanning'));
const ListSpin = lazy(() => import('../pages/ListSpin'));
const SpinReportScore = lazy(() => import('../pages/SpinReportScore'));
const GradePaper = lazy(() => import('../pages/GradePaper'));
const SpinReview = lazy(() => import('../pages/SpinReview'));
const ListQualityAssurance = lazy(() => import('../pages/ListQualityAssurance'));
const GradePaperQualityAssurance = lazy(() => import('../pages/GradePaperQualityAssurance'));
const QualityAssuranceReportingMembers = lazy(() => import('../pages/QualityAssuranceReportingMembers'));
const InsuranceQualitySurveyPaper = lazy(() => import('../pages/InsuranceQualitySurveyPaper'));
const ListRealizationOfOperationalAudit = lazy(() => import('../pages/ListRealizationOfOperationalAudit'));
const SupervisionResultsEvaluationCard = lazy(() => import('../pages/SupervisionResultsEvaluationCard'));
const AuditorsPerformance = lazy(() => import('../pages/AuditorsPerformance'));
const DetailsOfAuditorPerformance = lazy(() => import('../pages/DetailsOfAuditorPerformance'));
const SPAAuditorPerformance = lazy(() => import('../pages/SPAAuditorPerformance'));
const DetailsSPAAuditorPerformanceMember = lazy(() => import('../pages/DetailsSPAAuditorPerformanceMember'));
const QAAuditorPerformance = lazy(() => import('../pages/QAAuditorPerformance'));
const DetailsQAAuditorPerformance = lazy(() => import('../pages/DetailsQAAuditorPerformance'));
const AuditorPerformanceDraftLHA = lazy(() => import('../pages/AuditorPerformanceDraftLHA'));
const DetailsAuditorPerformanceDraftLHAMember = lazy(() => import('../pages/DetailsAuditorPerformanceDraftLHAMember'));
const AuditorPerformancePublishedLHA = lazy(() => import('../pages/AuditorPerformancePublishedLHA'));
const DetailsAuditorPerformancePublishedLHAMember = lazy(() =>
  import('../pages/DetailsAuditorPerformancePublishedLHAMember')
);
const AuditorPerformanceKEHP = lazy(() => import('../pages/AuditorPerformanceKEHP'));
const DetailsAuditorPerformanceKEHPMember = lazy(() => import('../pages/DetailsAuditorPerformanceKEHPMember'));
const DetailsSPAAuditorPerformanceBranch = lazy(() => import('../pages/DetailsSPAAuditorPerformanceBranch'));
const DetailsQAAuditorPerformanceBranch = lazy(() => import('../pages/DetailsQAAuditorPerformanceBranch'));
const DetailsAuditorPerformanceDraftLHABranch = lazy(() => import('../pages/DetailsAuditorPerformanceDraftLHABranch'));
const DetailsAuditorPerformancePublishedLHABranch = lazy(() =>
  import('../pages/DetailsAuditorPerformancePublishedLHABranch')
);
const DetailsAuditorPerformanceKEHPBranch = lazy(() => import('../pages/DetailsAuditorPerformanceKEHPBranch'));
const Authorization = lazy(() => import('../pages/Authorization'));
const LHA = lazy(() => import('../pages/LHA'));
const KEHP = lazy(() => import('../pages/KEHP'));
const FormDetailLHA = lazy(() => import('../pages/FormDetailLHA'));
const FormDetailKEHP = lazy(() => import('../pages/FormDetailKEHP'));
const FormDetailKEHPFinding = lazy(() => import('../pages/FormDetailKEHP/finding'));
// const AnnualAuditPLanningReportingDetail = lazy(() => import('../pages/AnnualAuditPLanningReportingDetail'));
const Notifications = lazy(() => import('../pages/Notifications'));

const DashboardMain = lazy(() => import('../pages/V2/DashboardMain'));
const DashboardLayoutProses = lazy(() => import('../components/Layout/DashboardLayoutProses'));
const DetailProjectLayout = lazy(() => import('../components/Layout/DetailProjectLayout'));
const DashboardProses = lazy(() => import('../pages/V2/DashboardProses'));
const ProjectAudit = lazy(() => import('../pages/V2/ProjectAudit'));
const DashboardDetailProject = lazy(() => import('../pages/V2/DashboardDetailProject'));
const PlanningDetailProject = lazy(() => import('../pages/V2/PlanningDetailProject'));
const PKADetailProject = lazy(() => import('../pages/V2/PKADetailProject'));
// const DetailPKA = lazy(() => import('../pages/V2/DetailPKA'));
const PKAOverview = lazy(() => import('../pages/V2/PKAOverview'));
const PKAResikoPengendalian = lazy(() => import('../pages/V2/PKAResikoPengendalian'));
const PKAUjiPengendalian = lazy(() => import('../pages/V2/PKAUjiPengendalian'));
const KKAPengendalian = lazy(() => import('../pages/V2/KKAPengendalian'));
const KKAPengujian = lazy(() => import('../pages/V2/KKAPengujian'));

const ResultDetailProject = lazy(() => import('../pages/V2/ResultDetailProject'));
const ResultsMain = lazy(() => import('../pages/V2/ResultsMain'));
const ResultFiles = lazy(() => import('../pages/V2/ResultFiles'));
const DetailResultFiles = lazy(() => import('../pages/V2/ResultFiles/DetailResultFiles'));
const ResultFieldworkReport = lazy(() => import('../pages/V2/ResultFieldworkReport'));
const ResultSupportingWorkpaper = lazy(() => import('../pages/V2/ResultSupportingWorkpaper'));

const ProceduresDetailProject = lazy(() => import('../pages/V2/ProceduresDetailProject'));
const ReviewDetailProject = lazy(() => import('../pages/V2/ReviewDetailProject'));
const TemuanDetailProject = lazy(() => import('../pages/V2/TemuanDetailProject'));
const TemuanInformation = lazy(() => import('../pages/V2/TemuanInformation'));
const TemuanFollowupRemediation = lazy(() => import('../pages/V2/TemuanFollowupRemediation'));

const ObjectAuditPrioritasMenu2 = lazy(() => import('../pages/V2/ObjectAuditPrioritasMenu2'));
const ObjectAuditListKantor = lazy(() => import('../pages/V2/ObjectAuditListKantor'));
const ObjectPrioritasSubMenu1 = lazy(() => import('../pages/V2/ObjectPrioritasSubMenu1'));
const ObjectPrioritasSubMenu1Detail = lazy(() => import('../pages/V2/ObjectPrioritasSubMenu1Detail'));

const IssueTracker = lazy(() => import('../pages/V2/IssueTracker'));
const Analitics = lazy(() => import('../pages/V2/Analitics'));

const Progress = lazy(() => import('../pages/V2/Progress'));
const ToDos = lazy(() => import('../pages/V2/ToDos'));
const Requests = lazy(() => import('../pages/V2/Requests'));
const CreateRequests = lazy(() => import('../pages/V2/Requests/CreateRequests'));
const DetailRequests = lazy(() => import('../pages/V2/Requests/DetailRequests'));
const MonitoringTL = lazy(() => import('../pages/V2/MonitoringTL'));
const MonitoringTLAdd = lazy(() => import('../pages/V2/MonitoringTL/AddMonitoringTL'));
const MonitoringTLEdit = lazy(() => import('../pages/V2/MonitoringTL/EditMonitoringTL'));
const MonitoringTLDetail = lazy(() => import('../pages/V2/MonitoringTL/DetailMonitoringTL'));
const MonitoringTLDetailTemuan = lazy(() => import('../pages/V2/MonitoringTL/DetailTemuanMonitoringTL'));
const ActionDetail = lazy(() => import('../pages/V2/ActionDetail'));
const RequestDetail = lazy(() => import('../pages/V2/RequestDetail'));

const DashboardLayoutObjectAuditPrioritas = lazy(() =>
  import('../components/Layout/DashboardLayoutObjectAuditPrioritas')
);
const DashboardLayoutMonitoringTL = lazy(() => import('../components/Layout/DashboardLayoutMonitoringTL'));
const DashboardLayoutGeneral = lazy(() => import('../components/Layout/DashboardLayoutGeneral'));

const QRCodeUsers = lazy(() => import('../pages/QRCodeUsers/index'));

const Index = () => {
  // useRouter();
  return (
    <>
      <Suspense fallback={<Loader show={true} />}>
        <BrowserRouter>
          <Routes>
            <Route path="/authorization" element={<Authorization />} />
            {/* <Route path="/" element={<Navigate to="/authorization" />} /> */}
            <Route path="/" element={<Navigate to="/impersonate-login" />} />
            {/* <Route path="/" element={() => <></>} /> */}
            <Route path="/impersonate-login" element={<Login />} />

            <Route path="/panel" element={<DashboardMain />} />
            <Route path="/action-detail" element={<ActionDetail />} />
            <Route path="/request-detail" element={<RequestDetail />} />
            <Route path="/dashboard-proses" element={<DashboardLayoutGeneral />}>
              <Route index element={<DashboardProses />} />

              <Route path="project">
                <Route index element={<ProjectAudit />} />

                <Route path="detail/:id" element={<DetailProjectLayout />}>
                  <Route index path="dashboard" element={<DashboardDetailProject />} />
                  <Route path="planning" element={<PlanningDetailProject />} />
                  <Route path="progress" element={<Progress />} />
                  <Route path="to-dos" element={<ToDos />} />
                  <Route path="requests">
                    <Route index element={<Requests />} />
                    <Route path="create" element={<CreateRequests />} />
                    <Route path="detail/:reqId" element={<DetailRequests />} />
                  </Route>

                  <Route path="pka">
                    <Route index element={<PKADetailProject />} />
                    {/* <Route path="detail-pka" element={<DetailPKA />} /> */}
                    <Route path=":fieldId/overview" element={<PKAOverview />} />
                    <Route path=":fieldId/risiko-pengendalian" element={<PKAResikoPengendalian />} />
                    <Route path=":fieldId/uji-pengendalian">
                      <Route index element={<PKAUjiPengendalian />} />
                      <Route path="pengendalian/:implementId" element={<KKAPengendalian />} />
                      <Route path="pengujian/:implementId" element={<KKAPengujian />} />
                    </Route>
                  </Route>
                  <Route path="result" element={<ResultDetailProject />}>
                    {/* <Route index path="" element={<ResultsMain />} /> */}
                    {/* <Route path="result-files" element={<ResultFiles />} /> */}
                    <Route path="result-files">
                      <Route index element={<ResultFiles />} />
                      <Route path="detail/:resultFileId" element={<DetailResultFiles />} />
                    </Route>
                    <Route path="fieldwork-reports" element={<ResultFieldworkReport />} />
                    <Route path="supporting-workpapers" element={<ResultSupportingWorkpaper />} />
                  </Route>

                  <Route path="procedures" element={<ProceduresDetailProject />} />
                  <Route path="review" element={<ReviewDetailProject />} />
                  <Route path="temuan">
                    <Route index element={<TemuanDetailProject />} />

                    <Route path=":findingId/temuan-information" element={<TemuanInformation />} />
                    <Route path=":findingId/temuan-follup-remediation" element={<TemuanFollowupRemediation />} />
                  </Route>
                </Route>
              </Route>
              {/* <Route path="object-prioritas-sub-menu-1" element={<ObjectPrioritasSubMenu1 />} /> */}
              <Route path="object-prioritas-sub-menu-1">
                <Route index element={<ObjectPrioritasSubMenu1 defaultPageView="index" />} />
                <Route path=":id" element={<ObjectPrioritasSubMenu1 defaultPageView="detail" />} />
              </Route>
              <Route path="object-prioritas-sub-menu-2">
                <Route index element={<ObjectAuditPrioritasMenu2 />} />
                <Route path=":cabang" element={<ObjectAuditListKantor />} />
              </Route>
              {/* <Route path="issue-tracker" element={<IssueTracker />} /> */}
              {/* <Route path="analitics" element={<Analitics />} /> */}
            </Route>
            <Route path="/dashboard-object-audit-prioritas" element={<DashboardLayoutObjectAuditPrioritas />}>
              <Route index element={<ObjectPrioritasSubMenu1 />} />
              <Route path="object-prioritas-sub-menu-1">
                <Route index element={<ObjectPrioritasSubMenu1 defaultPageView="index" />} />
                <Route path=":id" element={<ObjectPrioritasSubMenu1 defaultPageView="detail" />} />
              </Route>
              <Route path="object-prioritas-sub-menu-2">
                <Route index element={<ObjectAuditPrioritasMenu2 />} />
                <Route path=":cabang" element={<ObjectAuditListKantor />} />
              </Route>
            </Route>
            <Route path="/dashboard-monitoring-tindak-lanjut" element={<DashboardLayoutMonitoringTL />}>
              <Route index element={<MonitoringTL />} />
              <Route path="detail/:id" element={<MonitoringTLDetail />} />
              <Route path="detail-temuan/:temuanId" element={<MonitoringTLDetailTemuan />} />
              <Route path="add-monitoring-tl" element={<MonitoringTLAdd />} />
              <Route path="edit-monitoring-tl/:id" element={<MonitoringTLEdit />} />
            </Route>
            <Route path="/analitik" element={<DashboardLayoutGeneral />}>
              <Route index element={<Analitics />} />
            </Route>
            <Route path="/issue-tracker" element={<DashboardLayoutGeneral />}>
              <Route index element={<IssueTracker />} />
            </Route>

            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route index element={<Dashboard />} />
              {/* <Route path="object-audit" element={<ObjectAudit />} /> */}
              {/* <Route path="branch" element={<Branch />} /> */}
              <Route path="user-management" element={<UserManagement />} />
              <Route path="role-access" element={<RoleAccess />} />
              <Route path="uang-saku-harian-pegawai" element={<EmployeeDailyPocketMoney />} />
              {/* <Route path="uang-saku-harian-mitra" element={<PartnersDailyPocketMoney />} /> */}
              <Route path="tunjangan-audit" element={<AuditAllowance />} />
              {/* <Route path="biaya-taksi" element={<TaxiCost />} /> */}
              {/* <Route path="laporan-evaluasi-audit-awal" element={<InitialAuditEvaluation />} /> */}
              <Route path="spin-tingkat-maturitas" element={<SpinMaturityLevel />} />
              <Route path="spin-atribut" element={<SpinAttribute />} />
              <Route path="spin-titik-fokus" element={<SpinFocalPoint />} />
              <Route path="spin-unsur-pengendalian" element={<SpinControlElement />} />
              <Route path="sub-unsur-penilaian-qa" element={<QASubElementsofAssessment />} />
              <Route path="type-penilaian-qa" element={<QAAssessmentType />} />
              <Route path="unsur-penilaian-qa" element={<QAAssessmentElements />} />
              <Route path="bobot-obj-prioritas" element={<ObjPrioritasBobot />} />
              <Route path="fraud-obj-prioritas" element={<ObjPrioritasFraud />} />
              <Route path="perencanaan-audit-tahunan">
                <Route index element={<AnnualAuditPlanning defaultPageView="index" />} />
                <Route path=":id" element={<AnnualAuditPlanning defaultPageView="detail" />} />
                {/* <Route path="reporting/:id" element={<AnnualAuditPLanningReportingDetail />} /> */}
              </Route>
              <Route path="kode-temuan" element={<FindingCode />} />
              <Route path="aturan-perusahaan" element={<CompanyRegulations />} />
              <Route path="pengajuan-audit">
                <Route index element={<AuditSubmission />} />
                <Route path=":id" element={<FormAuditSubmission />} />
              </Route>
              <Route path="perencanaan-ptta" element={<PTTAPlanning />} />
              <Route path="perencanaan-bidang" element={<FieldPlanning />} />
              <Route path="perencanaan-urusan" element={<AffairsPlanning />} />
              <Route path="perencanaan-kantor" element={<OfficePlanning />} />
              <Route path="spin">
                <Route index element={<ListSpin />} />
                <Route path="kertas-nilai/:id" element={<GradePaper />} />
                <Route path="report-score/:id" element={<SpinReportScore />} />
                <Route path="review/:id" element={<SpinReview />} />
              </Route>
              <Route path="quality-assurance">
                <Route index element={<ListQualityAssurance />} />
                <Route path="kertas-nilai/:id" element={<GradePaperQualityAssurance />} />
                <Route path="report-score-anggota/:id" element={<QualityAssuranceReportingMembers />} />
                <Route path="kertas-survei/:id" element={<InsuranceQualitySurveyPaper />} />
              </Route>
              <Route path="realisasi-audit-operasional">
                <Route index element={<ListRealizationOfOperationalAudit />} />
                <Route path=":id" element={<SupervisionResultsEvaluationCard />} />
              </Route>
              <Route path="kinerja-auditor">
                <Route index element={<AuditorsPerformance />} />
                <Route path=":id" element={<DetailsOfAuditorPerformance />} />
                <Route path="spa" element={<SPAAuditorPerformance />} />
                <Route path="spa/user/:id" element={<DetailsSPAAuditorPerformanceMember />} />
                <Route path="spa/branch/:id" element={<DetailsSPAAuditorPerformanceBranch />} />
                <Route path="qa" element={<QAAuditorPerformance />} />
                <Route path="qa/user/:id" element={<DetailsQAAuditorPerformance />} />
                <Route path="qa/branch/:id" element={<DetailsQAAuditorPerformanceBranch />} />
                <Route path="draft-lha" element={<AuditorPerformanceDraftLHA />} />
                <Route path="draft-lha/user/:id" element={<DetailsAuditorPerformanceDraftLHAMember />} />
                <Route path="draft-lha/branch/:id" element={<DetailsAuditorPerformanceDraftLHABranch />} />
                <Route path="terbit-lha" element={<AuditorPerformancePublishedLHA />} />
                <Route path="terbit-lha/user/:id" element={<DetailsAuditorPerformancePublishedLHAMember />} />
                <Route path="terbit-lha/branch/:id" element={<DetailsAuditorPerformancePublishedLHABranch />} />
                <Route path="kehp" element={<AuditorPerformanceKEHP />} />
                <Route path="kehp/user/:id" element={<DetailsAuditorPerformanceKEHPMember />} />
                <Route path="kehp/branch/:id" element={<DetailsAuditorPerformanceKEHPBranch />} />
              </Route>
              <Route path="lha">
                <Route index element={<LHA />} />
                <Route path="detail/:id" element={<FormDetailLHA />} />
              </Route>
              <Route path="kehp">
                <Route index element={<KEHP />} />
                <Route path="detail/:id" element={<FormDetailKEHP />} />
                <Route path="detail/:id/finding/:findingId" element={<FormDetailKEHPFinding />} />
              </Route>
              <Route path="notifications" element={<Notifications />} />
            </Route>
          </Routes>
          <Routes>
            <Route path="qr-code">
              <Route path="detail/:id" element={<QRCodeUsers />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
      <Loader />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Index;
