import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { rootReducer } from './reducers/rootReducer';
import env from '../configs/env';

const middleware = [thunk];
const devTools =
  env().NODE_ENV !== 'production'
    ? composeWithDevTools(applyMiddleware(...middleware))
    : applyMiddleware(...middleware);

export const store = createStore(rootReducer, devTools);
